/* eslint-disable no-param-reassign */
import { ReactNode, useRef } from "react"
import { Opener, useControlledOrUncontrolled, useOpener } from "msutils"
import { unreachable } from "msutils/misc"
import { BaseLayout } from "../baseLayout"
import { AnimatedDiv, useAnimationPhase } from "../_internal/AnimatedDiv"
import { Tracking } from "../_internal/Tracking"
import useScreenSize from "../theme/useScreenSize"

// TODO
// * support size configuration (maxHeight, etc.)

export type PopupTheme = {
  margin: number
  background: string
  padding: number
  borderWidth: number
  borderColor: string
  borderRadius: number
  shadow: string
}

type SideX = "left" | "right" | "center"
type SideY = "top" | "bottom"
export type Side = `${SideY}-${SideX}`

function styleElementForSide({
  side,
  dropdown,
  container,
  margin,
}: {
  side: Side
  dropdown: HTMLElement
  container: HTMLElement
  margin: number
}) {
  const height = dropdown.clientHeight
  const width = dropdown.clientWidth
  const containerRect = container.getBoundingClientRect()
  if (side === "top-left") {
    dropdown.style.transformOrigin = "0px 100%"
    dropdown.style.top = `-${height + margin}px`
  } else if (side === "top-center") {
    dropdown.style.transformOrigin = "0px 100%"
    dropdown.style.top = `-${height + margin}px`
    dropdown.style.left = `${(containerRect.width - width) / 2}px`
  } else if (side === "top-right") {
    dropdown.style.transformOrigin = "0px 100%"
    dropdown.style.top = `-${height + margin}px`
    dropdown.style.right = "0"
  } else if (side === "bottom-left") {
    dropdown.style.transformOrigin = "0px 0px"
    dropdown.style.bottom = `-${height + margin}px`
  } else if (side === "bottom-center") {
    dropdown.style.transformOrigin = "0px 0px"
    dropdown.style.top = `calc(100% + ${margin}px)`
    dropdown.style.left = `${(containerRect.width - width) / 2}px`
  } else if (side === "bottom-right") {
    dropdown.style.transformOrigin = "0px 0px"
    dropdown.style.top = `calc(100% + ${margin}px)`
    dropdown.style.right = "0"
  } else {
    unreachable(side)
  }
}

function checkSide({
  side,
  dropdown,
  containerRect,
  margin,
}: {
  side: SideX | SideY
  dropdown: HTMLElement
  containerRect: DOMRect
  margin: number
}) {
  const height = dropdown.clientHeight
  const width = dropdown.clientWidth

  if (side === "left") {
    const protrusionOverEdge = width - containerRect.width
    return protrusionOverEdge <= window.innerWidth - containerRect.right
  } else if (side === "center") {
    const protrusionOverEdge = (width - containerRect.width) / 2
    return (
      protrusionOverEdge <= containerRect.left &&
      protrusionOverEdge <= window.innerWidth - containerRect.right
    )
  } else if (side === "right") {
    const protrusionOverEdge = width - containerRect.width
    return protrusionOverEdge <= containerRect.left
  } else if (side === "top") {
    return height + margin <= containerRect.top
  } else if (side === "bottom") {
    return height + margin <= window.innerHeight - containerRect.bottom
  } else {
    return unreachable(side)
  }
}

function getBestSide({
  dropdown,
  container,
  defaultSideX,
  defaultSideY,
  fallbackSideX,
  fallbackSideY,
  margin,
}: {
  dropdown: HTMLElement
  container: HTMLDivElement
  defaultSideX?: SideX
  defaultSideY?: SideY
  fallbackSideX?: SideX
  fallbackSideY?: SideY
  margin: number
}): Side {
  const checkProps = { dropdown, containerRect: container.getBoundingClientRect(), margin }

  let leftOrRight: "left" | "right" | "center"
  if (defaultSideX && checkSide({ side: defaultSideX, ...checkProps })) {
    leftOrRight = defaultSideX
  } else if (!checkSide({ side: "right", ...checkProps })) {
    leftOrRight = "left"
  } else if (!checkSide({ side: "left", ...checkProps })) {
    leftOrRight = "right"
  } else {
    leftOrRight = fallbackSideX ?? "left"
  }

  let topOrBottom: "top" | "bottom"
  if (defaultSideY && checkSide({ side: defaultSideY, ...checkProps })) {
    topOrBottom = defaultSideY
  } else if (!checkSide({ side: "top", ...checkProps })) {
    topOrBottom = "bottom"
  } else if (!checkSide({ side: "bottom", ...checkProps })) {
    topOrBottom = "top"
  } else {
    topOrBottom = fallbackSideY ?? "bottom"
  }

  return `${topOrBottom}-${leftOrRight}`
}

type Trigger = "hover" | "focus" | "click"
type Size = {
  width: number
  height: number
  maxWidth: number
  maxHeight: number
}

export function Popup({
  opener: opener_,
  side,
  getSize,
  defaultSideX,
  defaultSideY,
  fallbackSideX,
  fallbackSideY,
  content,
  theme,
  triggers = ["click", "focus"],
  keepMounted,
  children,
}: {
  children: ReactNode
  opener?: Opener
  side?: Side
  defaultSideX?: SideX
  defaultSideY?: SideY
  fallbackSideX?: SideX
  fallbackSideY?: SideY
  getSize?: (props: { anchor: HTMLElement }) => Partial<Size>
  content: ReactNode
  theme: PopupTheme
  triggers: Trigger[]
  /** Deprecated */
  keepMounted?: boolean
}) {
  // Note: for now, use portals on desktop and don't use portals on mobile
  const sz = useScreenSize()
  const containerRef = useRef<HTMLDivElement>(null)
  const dropdownRef = useRef<HTMLElement | null>(null)
  const opener = useControlledOrUncontrolled(opener_, useOpener())
  const durationMs = 100
  const animationPhase = useAnimationPhase(opener.isActive, { durationMs })
  const size = containerRef.current ? getSize?.({ anchor: containerRef.current }) ?? null : null

  return (
    <BaseLayout.RawDiv
      ref={containerRef}
      tabIndex={-1}
      onMouseEnter={() => {
        if (!triggers.includes("hover")) return
        opener.setActive()
      }}
      onMouseLeave={() => {
        if (!triggers.includes("hover")) return
        opener.setInactive()
      }}
      onBlur={(e) => {
        if (!triggers.includes("focus")) return

        const targetInDropdown =
          e.relatedTarget instanceof Node && !!containerRef.current?.contains(e.relatedTarget)
        if (!targetInDropdown) opener.setInactive()
      }}
      onClick={(e) => {
        if (!triggers.includes("click")) return

        const targetInDropdown =
          e.target instanceof Node && !!dropdownRef.current?.contains(e.target)
        // TODO: shouldn't be necessary, but we do a bunch of portal nonsense
        const targetIsActualChild =
          e.target instanceof Node && containerRef.current?.contains(e.target)
        if (!targetInDropdown && targetIsActualChild) opener.toggle()
      }}
      style={{ position: "relative" }}
    >
      {children}
      <Tracking
        item={containerRef.current}
        active={animationPhase !== "out"}
        setInactive={opener.setInactive}
        options={{ position: sz === "sm" ? "absolute" : "fixed" }}
        keepMounted={keepMounted}
      >
        <AnimatedDiv
          durationMs={durationMs}
          animationProperties="transform opacity"
          phase={animationPhase}
          ref={(el) => {
            dropdownRef.current = el
            // set initial animation props
            if (el && containerRef.current) {
              const bestSide =
                side ??
                getBestSide({
                  dropdown: el,
                  container: containerRef.current,
                  margin: theme.margin,
                  defaultSideX,
                  defaultSideY,
                  fallbackSideX,
                  fallbackSideY,
                })
              styleElementForSide({
                side: bestSide,
                dropdown: el,
                container: containerRef.current,
                margin: theme.margin,
              })
            }
          }}
          keepMounted={keepMounted}
          style={{
            pointerEvents: animationPhase === "out" ? undefined : "auto",
            position: "absolute",
            height: "fit-content",
            background: theme.background,
            boxShadow: theme.shadow,
            border: `${theme.borderWidth}px solid ${theme.borderColor}`,
            borderRadius: `${theme.borderRadius}px`,
            padding: theme.padding,
            ...size,
            // this should be set once the side is decided, but the component starts rendering before the side gets set, which is a minor problem visually, but makes this required
            transformOrigin: "0 0",
          }}
          styleInOut={{ opacity: 0, transform: "scaleY(0.1)" }}
        >
          {content}
        </AnimatedDiv>
      </Tracking>
    </BaseLayout.RawDiv>
  )
}
