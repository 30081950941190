import { useCallback, useEffect, useRef, useState, useId } from "react"
import BigNumber from "bignumber.js"

export * from "./format"

// eslint-disable-next-line mosaic-js/no-unnamed-boolean-arg
export function xor(a: boolean, b: boolean) {
  return (a || b) && a !== b
}

// eslint-disable-next-line mosaic-js/no-unnamed-boolean-arg
export function xnor(a: boolean, b: boolean) {
  return !xor(a, b)
}

// eslint-disable-next-line mosaic-js/no-bignumber-zero
export const Zero = BigNumber(0)

export function random(length: number = 14) {
  let result = ""
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
  const charactersLength = characters.length
  let counter = 0
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
    counter += 1
  }
  return result
}

export function capitalize(val: string) {
  if (val.length === 0) {
    return val
  } else {
    return `${val[0].toUpperCase()}${val.slice(1)}`
  }
}

export function toSentenceCase(val: string) {
  return capitalize(
    Array.from(val).reduce((p, c) => {
      if (c === "_") {
        return `${p} `
      } else if (c.toUpperCase() === c) {
        return `${p} ${c.toLowerCase()}`
      } else {
        return `${p}${c}`
      }
    }, ""),
  )
}

export function useOnMount(fn: Parameters<typeof useEffect>[0]) {
  const fnRef = useRef(fn).current
  useEffect(fnRef, [fnRef])
}

export function nullCoalescer<T extends {}, S extends {}>(
  fn: (value: T) => S,
): <TActual extends T | null>(value: TActual) => TActual extends null ? S | null : S {
  return <TActual extends T | null>(value: TActual) => {
    if (value === null) {
      return null as any
    } else {
      return fn(value)
    }
  }
}

export function useRerender() {
  // eslint-disable-next-line
  const [_, setN] = useState(1)
  return useCallback(() => setN((n) => (n + 1) % 12345678), [])
}

export function useRunEvery(s: number, cb: () => void) {
  const cbRef = useRef(cb)
  useEffect(() => {
    const t = setInterval(() => cbRef.current(), s * 1000)
    return () => {
      clearInterval(t)
    }
  }, [s, cbRef])
}

export function useHtmlId(pre?: string) {
  const id1 = useId()
  const id2 = useId()
  return `${pre ?? "msutils"}-${id1}-${id2}`.replaceAll(":", "")
}

export const empty = {}
export const emptyList = []

export function arrayWithLength(n: number) {
  return Array(n)
    .fill(undefined)
    .map((_, i) => i)
}

export type OptionalProp<
  TKey extends string,
  TVal,
  TCondition = TVal extends (...props: any) => infer TReturn
    ? {} extends TReturn
      ? true
      : false
    : {} extends TVal
    ? true
    : false,
> = TCondition extends true ? { [_ in TKey]?: TVal } : { [_ in TKey]: TVal }

export type OptionalTupleProp<TValue> = {} extends TValue ? [arg?: TValue] : [TValue]

export type Assume<T, U> = T extends U ? T : U

export function countLeft<T>(arr: T[], index: number, cond: (x: T) => boolean) {
  let counter = 0
  let i = index - 1
  while (i >= 0) {
    if (cond(arr[i])) {
      counter += 1
      i -= 1
    } else {
      break
    }
  }
  return counter
}

export function countRight<T>(arr: T[], index: number, cond: (x: T) => boolean) {
  let counter = 0
  let i = index + 1
  while (i < arr.length) {
    if (cond(arr[i])) {
      counter += 1
      i += 1
    } else {
      break
    }
  }
  return counter
}

export async function delay(durationMs: number) {
  await new Promise((res) => {
    setTimeout(res, durationMs)
  })
}
