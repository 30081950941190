/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ComplianceDocumentTypeEVerifyType =
  (typeof ComplianceDocumentTypeEVerifyType)[keyof typeof ComplianceDocumentTypeEVerifyType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ComplianceDocumentTypeEVerifyType = {
  e_verify: "e_verify",
} as const
