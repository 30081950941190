import { G } from "msutils/global-ctx"

/** For internal use only */
// eslint-disable-next-line
export interface ComponentTheme {}

/** Override with theme: UserTheme.define(...) prop */
export interface UserThemeObj {}

export namespace UserTheme {
  type ClientDefinedTheme = {
    [K in keyof ComponentTheme]: {
      primary: ComponentTheme[K]
      // other variants
    }
  }

  export function define<T extends ClientDefinedTheme>(themes: T) {
    return themes
  }

  type Merge<A extends object, B extends object> = {
    [K in keyof A]: K extends keyof B ? B[K] : A[K]
  }
  export type Theme = Merge<{ theme: ClientDefinedTheme }, UserThemeObj>["theme"] &
    ClientDefinedTheme
}

declare module "msutils/global-ctx" {
  interface GCtx {
    compass: UserTheme.Theme
  }
}

/** For internal use only */
export function useTheme<
  K extends keyof UserTheme.Theme,
  Variant extends keyof UserTheme.Theme[K] = "primary",
>(k: K, variant?: Variant) {
  const v = variant ?? ("primary" as Variant)
  const t = G.useValue("compass")
  return t[k][v]
}

/** For internal use only */
export type VariantsOf<K extends keyof ComponentTheme> = keyof UserTheme.Theme[K]
