import { useEffect, useState } from "react"
import { useOnChange } from "../common"

export function isInView(container: HTMLElement, el: HTMLElement) {
  const topOfVisibility = container.scrollTop
  const bottomOfVisibility = topOfVisibility + container.clientHeight
  const topOfEl = el.offsetTop
  const bottomOfEl = topOfEl + el.clientHeight
  return bottomOfEl > topOfVisibility && topOfEl < bottomOfVisibility
}

function getSizeOfText(
  text: string,
  conf?: { classNames?: string; fixedWidth?: number; inset?: [number, number] },
) {
  const div = document.createElement("div")
  div.style.paddingTop = conf?.inset?.[0] ? `${conf.inset[0]}px` : ""
  div.style.paddingBottom = conf?.inset?.[0] ? `${conf.inset[0]}px` : ""
  div.style.paddingRight = conf?.inset?.[1] ? `${conf.inset[1]}px` : ""
  div.style.paddingLeft = conf?.inset?.[1] ? `${conf.inset[1]}px` : ""
  div.innerText = text || " "
  div.className = conf?.classNames ?? ""
  div.style.visibility = "hidden"
  div.style.position = "absolute"
  div.style.top = "0px"
  div.style.background = "white"
  div.style.whiteSpace = "pre-wrap"
  div.style.width = conf?.fixedWidth ? `${conf.fixedWidth}px` : "min-content"
  document.body.appendChild(div)
  const size = { width: div.clientWidth, height: div.clientHeight }
  document.body.removeChild(div)
  return size
}

type Size = { width: number; height: number }
export function useSizeOfText(
  text: string,
  conf?: { classNames?: string; fixedWidth?: number; inset?: [number, number] },
) {
  const [size, setSize] = useState<Size>(getSizeOfText(text, conf))
  useOnChange(
    [text, conf?.classNames, conf?.fixedWidth, conf?.inset?.[0], conf?.inset?.[1]],
    () => {
      setSize(getSizeOfText(text, conf))
    },
  )

  return size
}

export function useDebugRenders(name: string) {
  useEffect(() => {
    // eslint-disable-next-line
    console.log(`[${name}] mounted`)
  }, [name])
  // eslint-disable-next-line
  console.log(`[${name}] rendered`)
}

export function useDebugStability(name: string, variable: any) {
  useEffect(() => {
    // eslint-disable-next-line
    console.log(`[${name}] changed`)
  }, [name, variable])
  // eslint-disable-next-line
  console.log(`[${name}] rendered`)
}
