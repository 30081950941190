import { t } from "content"
import { nullCoalescer } from "./common"
import { unreachable } from "./misc"

function twoNumericPlaces(n: number) {
  return n.toString().padStart(2, "0")
}

export function baseIso(y: number, m: number, d: number) {
  return [y.toString(), twoNumericPlaces(m), twoNumericPlaces(d)].join("-")
}

export class MSDate {
  private year: number

  private month: number

  private day: number

  constructor(value: string) {
    const match = value.match(/^(\d{4})-(\d{2})-(\d{2})$/)
    if (!match) {
      throw new Error(`Unable to parse date: ${value}`)
    }

    const [year, month, day] = match.slice(1).map(Number)
    const d = new Date(year, month - 1, day)
    if (d.getDate() !== day || d.getMonth() !== month - 1 || d.getFullYear() !== year) {
      throw new Error(`Invalid date: ${value}`)
    }

    ;[this.year, this.month, this.day] = [year, month, day]
  }

  static init = nullCoalescer((value: string) => {
    return new MSDate(value)
  })

  private toNativeDate() {
    return new Date(this.year, this.month - 1, this.day)
  }

  private static fromNativeDate(value: Date) {
    return MSDate.init(baseIso(value.getFullYear(), value.getMonth() + 1, value.getDate()))
  }

  valueOf() {
    return this.toNativeDate().getTime()
  }

  private static DAY_EPOCH_VALUE = 1000 * 60 * 60 * 24

  addDays(n: number) {
    const nativeDate = this.toNativeDate()
    nativeDate.setDate(nativeDate.getDate() + n)
    return MSDate.fromNativeDate(nativeDate)
  }

  addMonths(n: number) {
    const nativeDate = this.toNativeDate()
    nativeDate.setMonth(nativeDate.getMonth() + n)
    return MSDate.fromNativeDate(nativeDate)
  }

  addYears(n: number) {
    return MSDate.init(baseIso(this.year + n, this.month, this.day))
  }

  setDays(n: number) {
    return MSDate.init(baseIso(this.year, this.month, n))
  }

  static today() {
    const date = new Date()
    return MSDate.fromNativeDate(date)
  }

  static yesterday() {
    const todayDate = MSDate.today()
    return todayDate.addDays(-1)
  }

  static tomorrow() {
    const todayDate = MSDate.today()
    return todayDate.addDays(1)
  }

  eq(other: MSDate) {
    return this.year === other.year && this.month === other.month && this.day === other.day
  }

  gt(other: MSDate) {
    return this.valueOf() > other.valueOf()
  }

  lt(other: MSDate) {
    return this.valueOf() < other.valueOf()
  }

  gte(other: MSDate) {
    return this.valueOf() >= other.valueOf()
  }

  lte(other: MSDate) {
    return this.valueOf() <= other.valueOf()
  }

  isPast() {
    return this.lt(MSDate.today())
  }

  isPastOrToday() {
    return this.isPast() || this.eq(MSDate.today())
  }

  isFuture() {
    return this.gt(MSDate.today())
  }

  isFutureOrToday() {
    return this.isFuture() || this.eq(MSDate.today())
  }

  isToday() {
    return this.eq(MSDate.today())
  }

  iso() {
    return baseIso(this.year, this.month, this.day)
  }

  getDayOfWeek() {
    return this.toNativeDate().getDay()
  }

  isWeekend() {
    return [0, 6].includes(this.getDayOfWeek())
  }

  format(f: "numeric" | "standard" = "standard") {
    if (f === "standard") {
      const d = this.toNativeDate()
      const monthName = d.toLocaleString("en-US", { month: "short" })
      return `${monthName} ${this.day}, ${this.year}`
    } else if (f === "numeric") {
      return `${twoNumericPlaces(this.month)}/${twoNumericPlaces(this.day)}/${this.year}`
    } else {
      return unreachable(f)
    }
  }

  formatMonth(f: "numeric" | "standard" = "standard") {
    if (f === "standard") {
      const d = this.toNativeDate()
      const monthName = d.toLocaleString("en-US", { month: "short" })
      return `${monthName} ${this.year}`
    } else if (f === "numeric") {
      return `${twoNumericPlaces(this.month)}-${this.year}`
    } else {
      return unreachable(f)
    }
  }

  static formatRange(start: MSDate, end: MSDate) {
    const startMonth = start.toNativeDate().toLocaleString("en-US", { month: "short" })
    const endMonth = end.toNativeDate().toLocaleString("en-US", { month: "short" })

    if (start.year !== end.year) {
      return `${start.format()} – ${end.format()}`
    } else if (startMonth !== endMonth) {
      return `${startMonth} ${start.day} – ${endMonth} ${end.day}, ${start.year}`
    } else {
      return `${startMonth} ${start.day}–${end.day}, ${start.year}`
    }
  }

  /** Inclusive */
  isBetween(start: MSDate, end: MSDate) {
    return this.gte(start) && this.lte(end)
  }

  relative(other: MSDate = MSDate.today()) {
    const deltaDays = Math.floor(
      Math.abs(this.valueOf() - other.valueOf()) / MSDate.DAY_EPOCH_VALUE,
    )

    if (deltaDays > 365) {
      const deltaYears = Math.floor(deltaDays / 365)
      if (deltaYears === 1) {
        return t("1 year")
      } else {
        return t("{{ X }} years", { X: deltaYears })
      }
    } else if (deltaDays > 31) {
      const deltaMonths = Math.floor(deltaDays / 31)
      if (deltaMonths === 1) {
        return t("1 month")
      } else {
        return t("{{ X }} months", { X: deltaMonths })
      }
    } else {
      if (deltaDays === 1) {
        return t("1 day")
      } else {
        return t("{{ X }} days", { X: deltaDays })
      }
    }
  }

  getMonth() {
    return this.month
  }

  getDay() {
    return this.day
  }

  getYear() {
    return this.year
  }
}

const daysOfWeek = {
  sunday: 0,
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
}

export function getNextOfDay(day: keyof typeof daysOfWeek) {
  const dayNum = daysOfWeek[day]
  const tomorrow = MSDate.tomorrow()
  const tomorrowNum = tomorrow.getDayOfWeek()
  const daysTillTarget = (dayNum - tomorrowNum + 7) % 7
  return tomorrow.addDays(daysTillTarget)
}
