import { createContext, ReactNode, useContext, useMemo } from "react"
import { Opener } from "msutils"
import { createPortal } from "react-dom"
import { BaseLayout } from "../baseLayout"
import { useTheme, VariantsOf } from "../theme"
import { AnimatedDiv, useAnimationPhase } from "../_internal/AnimatedDiv"
import { PortalRootId } from "../_internal/constants"
import { OpenerInterruptProps, useOpenerInterrupt } from "../_internal/OpenerInterruptProvider"

declare module "../theme" {
  interface ComponentTheme {
    modal: {
      shadowColor: string
      shadowBlur: boolean
    }
  }
}

const Ctx = createContext<OpenerInterruptProps | undefined>(undefined)

export function useModalContext() {
  const ctx = useContext(Ctx)
  if (!ctx) throw new Error("Context error")
  return ctx
}

export function Modal({
  opener,
  variant,
  children,
}: {
  opener: Opener
  variant?: VariantsOf<"modal">
  children: ReactNode
}) {
  const theme = useTheme("modal", variant)
  const durationMs = 200
  const animationPhase = useAnimationPhase(opener.isActive, { durationMs })

  const portalRoot = useMemo(() => document.getElementById(PortalRootId)!, [])
  const openerInterruptProps = useOpenerInterrupt(opener.setInactive)

  const content = (
    <>
      <AnimatedDiv
        phase={animationPhase}
        durationMs={durationMs}
        animationProperties="opacity"
        style={{
          position: "fixed",
          inset: 0,
          backdropFilter: theme.shadowBlur ? "blur(5px)" : undefined,
          background: theme.shadowColor,
        }}
        styleInOut={{ opacity: 0 }}
        onClick={openerInterruptProps.close}
        keepMounted
      />
      <AnimatedDiv
        durationMs={durationMs}
        phase={animationPhase}
        style={{
          pointerEvents: "none",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "fixed",
          inset: 0,
        }}
        styleInOut={{ transform: "scale(0.1)", opacity: 0 }}
        animationProperties="transform opacity"
      >
        <BaseLayout.RawDiv style={{ pointerEvents: "auto" }}>
          <Ctx.Provider value={openerInterruptProps}>{children}</Ctx.Provider>
        </BaseLayout.RawDiv>
      </AnimatedDiv>
    </>
  )

  const portal = createPortal(content, portalRoot)
  return <>{portal}</>
}
