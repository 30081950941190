/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ListAssignmentListRowsOrdering =
  (typeof ListAssignmentListRowsOrdering)[keyof typeof ListAssignmentListRowsOrdering]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListAssignmentListRowsOrdering = {
  status: "status",
  "-status": "-status",
  title: "title",
  "-title": "-title",
  due_date: "due_date",
  "-due_date": "-due_date",
  description: "description",
  "-description": "-description",
  has_target_project: "has_target_project",
  "-has_target_project": "-has_target_project",
  created_at: "created_at",
  "-created_at": "-created_at",
  updated_at: "updated_at",
  "-updated_at": "-updated_at",
  assignee_name: "assignee_name",
  "-assignee_name": "-assignee_name",
  target_display_name: "target_display_name",
  "-target_display_name": "-target_display_name",
} as const
