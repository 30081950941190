import { ReactNode } from "react"
import { BaseLayout } from "../../baseLayout"
import Icon from "../../data/Icon"
import Typography from "../../data/Typography"
import { useTheme, VariantsOf } from "../../theme"
import { Popup, PopupTheme } from "../Popup"

declare module "../../theme" {
  interface ComponentTheme {
    tooltip: PopupTheme & {
      color: string
    }
  }
}

type Props = {
  message: ReactNode
  inactive?: boolean
  variant?: VariantsOf<"tooltip">
  children: ReactNode
}

function Tooltip({ message, inactive, variant, children }: Props) {
  const theme = useTheme("tooltip", variant)

  if (inactive) {
    return <>{children}</>
  } else {
    return (
      <Popup
        content={
          <BaseLayout.View
            overridesDONOTUSE={{ whiteSpace: "pre-wrap", width: "max-content", maxWidth: 200 }}
          >
            <Typography variant="label" rawColor={theme.color}>
              {message}
            </Typography>
          </BaseLayout.View>
        }
        theme={theme}
        triggers={["hover"]}
        defaultSideX="center"
        defaultSideY="top"
      >
        {children}
      </Popup>
    )
  }
}

function Info(props: { message: ReactNode; inactive?: boolean }) {
  return (
    <Tooltip {...props}>
      <Icon name="info" height={16} color="text-th-text-disabled" />
    </Tooltip>
  )
}

export default Object.assign(Tooltip, { Info })
