import { ReactNode } from "react"
import { Opener } from "msutils"
import { Popup, PopupTheme } from "../layout/Popup"
import { useTheme, VariantsOf } from "../theme"

declare module "../theme" {
  interface ComponentTheme {
    dropdown: PopupTheme
  }
}

export function Dropdown({
  opener,
  content,
  variant,
  keepMounted,
  children,
}: {
  children: ReactNode
  opener?: Opener
  content: ReactNode
  variant?: VariantsOf<"dropdown">
  /** Deprecated */
  keepMounted?: boolean
}) {
  const theme = useTheme("dropdown", variant)

  return (
    <Popup
      theme={theme}
      opener={opener}
      content={content}
      keepMounted={keepMounted}
      triggers={["focus", "click"]}
    >
      {children}
    </Popup>
  )
}
