/* eslint-disable no-param-reassign */
import { CSSProperties, ReactNode, useEffect, useMemo } from "react"
import { useStabilized } from "msutils"
import { unreachable } from "msutils/misc"
import { BaseLayout } from "../baseLayout"
import useScreenSize from "../theme/useScreenSize"

type TrackingOptions = {
  scroll?: "dismiss" | "ignore"
  position?: "fixed" | "absolute"
}

function debounceWithRAF<TArgs extends any[], TRes>(func: (...args: TArgs) => TRes) {
  let rafId: number | null = null

  return function x(this: any, ...args: TArgs) {
    if (rafId !== null) cancelAnimationFrame(rafId)
    rafId = requestAnimationFrame(() => {
      func.apply(this, args)
      rafId = null
    })
  }
}

// eslint-disable-next-line
function useScrollListener(active: boolean, cb: (e: Event) => void) {
  const stableCb = useStabilized(cb)
  useEffect(() => {
    if (active) {
      const handle = debounceWithRAF((e: Event) => {
        stableCb.current(e)
      })
      window.addEventListener("scroll", handle, { capture: true })

      return () => {
        window.removeEventListener("scroll", handle, { capture: true })
      }
    } else {
      return () => undefined
    }
  }, [active, stableCb])
}

function QualifiedTracking({
  item,
  active,
  children,
  setInactive,
  options,
}: {
  item: HTMLElement
  active: boolean
  children: ReactNode
  setInactive?: () => void
  options: TrackingOptions
}) {
  const sz = useScreenSize()
  const { scroll = sz === "sm" ? "ignore" : "dismiss", position = "fixed" } = options
  const style = useMemo((): CSSProperties => {
    if (!active) {
      return { position, opacity: 0 }
    } else if (position === "fixed") {
      const rect = item.getBoundingClientRect()
      return {
        position,
        top: rect.top,
        left: rect.left,
        width: rect.width,
        height: rect.height,
      }
    } else {
      return { position, inset: 0 }
    }
  }, [item, position, active])

  useScrollListener(active && scroll !== "ignore", (e) => {
    if (scroll === "ignore") {
      //
    } else if (scroll === "dismiss") {
      if (!e.target || !(e.target instanceof Node) || !item.contains(e.target)) {
        setInactive?.()
      }
    } else {
      unreachable(scroll)
    }
  })

  // position: fixed always creates a new stacking context, so I need to set zIndex here
  return (
    <BaseLayout.RawDiv style={{ ...style, pointerEvents: "none", zIndex: 1, isolation: "isolate" }}>
      {children}
    </BaseLayout.RawDiv>
  )
}

export function Tracking({
  item,
  active,
  setInactive,
  options,
  keepMounted,
  children,
}: {
  item: HTMLElement | null
  active: boolean
  setInactive?: () => void
  options?: TrackingOptions
  children: ReactNode
  keepMounted?: boolean
}) {
  if (item) {
    if (active || keepMounted) {
      return (
        <QualifiedTracking
          // TODO: this only exists to support keepMounted, which is deprecated
          active={active}
          item={item}
          setInactive={setInactive}
          options={options ?? {}}
        >
          {children}
        </QualifiedTracking>
      )
    } else {
      return null
    }
  } else {
    return null
  }
}
