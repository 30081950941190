import { useCallback, useEffect, useMemo, useRef, useState } from "react"

export function useOnChange(deps: any[], fn: () => any) {
  const fnRef = useRef(fn)
  fnRef.current = fn
  useEffect(() => {
    fnRef.current()
    // eslint-disable-next-line
  }, deps)
}

export function useControlledOrUncontrolled<T>(
  controlledValue: T | undefined,
  uncontrolledValue: T,
) {
  const initialControlled = useRef(controlledValue).current
  if (initialControlled) {
    if (controlledValue) {
      return controlledValue
    } else {
      // eslint-disable-next-line
      console.error("Component changed from controlled to uncontrolled")
      return uncontrolledValue
    }
  } else if (controlledValue) {
    // eslint-disable-next-line
    console.error("Component changed from uncontrolled to controlled")
    return controlledValue
  } else {
    return uncontrolledValue
  }
}

export function useStabilized<T>(value: T) {
  const ref = useRef(value)
  ref.current = value
  return ref
}

export function useOnChangeOnly(deps: unknown[], fn: Parameters<typeof useEffect>[0]) {
  const isFirstRender = useRef(true)
  const stableFn = useStabilized(fn)
  useEffect(() => {
    if (!isFirstRender.current) {
      stableFn.current()
    } else {
      isFirstRender.current = false
    }
    // eslint-disable-next-line
  }, [isFirstRender, stableFn, ...deps])
}

export interface Opener {
  isActive: boolean
  toggle: () => void
  setActive: () => void
  setInactive: () => void
}

export function useOpener(defaultValue?: boolean): Opener {
  const [s, setS] = useState(defaultValue ?? false)
  const setActive = useCallback(() => setS(true), [])
  const setInactive = useCallback(() => setS(false), [])
  const toggle = useCallback(() => setS((x) => !x), [])

  return useMemo(
    () => ({ isActive: s, setActive, setInactive, toggle }),
    [s, setActive, setInactive, toggle],
  )
}
