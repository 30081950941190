/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ListProjectExpensesOrdering =
  (typeof ListProjectExpensesOrdering)[keyof typeof ListProjectExpensesOrdering]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListProjectExpensesOrdering = {
  payee_business_name: "payee_business_name",
  "-payee_business_name": "-payee_business_name",
  date: "date",
  "-date": "-date",
  no_project: "no_project",
  "-no_project": "-no_project",
  hidden_from_non_employees: "hidden_from_non_employees",
  "-hidden_from_non_employees": "-hidden_from_non_employees",
  is_void: "is_void",
  "-is_void": "-is_void",
  approval_state: "approval_state",
  "-approval_state": "-approval_state",
  from_business_name: "from_business_name",
  "-from_business_name": "-from_business_name",
  from_business_address: "from_business_address",
  "-from_business_address": "-from_business_address",
  to_business_name: "to_business_name",
  "-to_business_name": "-to_business_name",
  to_business_address: "to_business_address",
  "-to_business_address": "-to_business_address",
  invoice_type: "invoice_type",
  "-invoice_type": "-invoice_type",
  contract_seqno: "contract_seqno",
  "-contract_seqno": "-contract_seqno",
  total_amount: "total_amount",
  "-total_amount": "-total_amount",
  from_qb: "from_qb",
  "-from_qb": "-from_qb",
  created_at: "created_at",
  "-created_at": "-created_at",
  updated_at: "updated_at",
  "-updated_at": "-updated_at",
} as const
