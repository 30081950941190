import { createContext, ReactNode, useContext } from "react"

// eslint-disable-next-line
export interface GCtx {}

export namespace G {
  const c = createContext<GCtx | undefined>(undefined)

  export function Provider({ children, values }: { children: ReactNode; values: GCtx }) {
    return <c.Provider value={values}>{children}</c.Provider>
  }

  export function useValue<K extends keyof GCtx>(k: K): GCtx[K] {
    const globalCtx = useContext(c)
    if (!globalCtx) throw new Error("GlobalCtx is required")
    return globalCtx[k]
  }
}
