import { Fragment, ReactNode } from "react"
import Button from "compass/data/Button"
import DropdownButton from "compass/data/Button/DropdownButton"
import { Option } from "compass/data/Button/DropdownButton/internal-utils"
import Icon from "compass/data/Icon"
import { MSArray } from "msutils"
import { unreachable } from "msutils/misc"
import { theme2 } from "theme2"
import { ContextBuilder, useOpener } from "msutils/common"
import Typography from "compass/data/Typography"
import { cn } from "msutils/classnames"
import Divider from "compass/data/Divider"
import useScreenSize from "compass/theme/useScreenSize"
import Tooltip from "compass/layout/Tooltip"
import { Dropdown } from "compass/v2/Dropdown"
import { BaseLayout } from "compass/baseLayout"
import { performQualify } from "./internal-utils"
import { ActionConfig, ThemeName, ThemeProps } from "./types"
import { getButtonThemeFromThemeName } from "./theme"

const { useContext, Provider } = ContextBuilder<ThemeProps>()

export { Provider as UIConfigProvider }

export function MenuOptionMount<TQualifyCtx, TMountProps, TMountCtx extends object>({
  name,
  theme,
  qualify,
  getMountProps,
  useMountCtx,
  Mount: ComponentMount,
  onClick,
  icon,
  endIcon,
  customIconDISCOURAGED,
}: ActionConfig<TQualifyCtx, TMountProps, TMountCtx>) {
  const mountCtx = useMountCtx()
  const qual = performQualify(qualify)

  if (qual.type === "hidden") {
    return null
  } else if (qual.type === "disabled") {
    return (
      <div className="py-0.5 rounded-4">
        <Option
          name={name}
          icon={customIconDISCOURAGED || (icon && <Icon name={icon} />)}
          endIcon={endIcon && <Icon name={endIcon} />}
          color={theme === "danger" ? "text-th-red-warning" : undefined}
          disabled
          disabledMessage={qual.message}
        />
      </div>
    )
  } else if (qual.type === "qualified") {
    const ctx = { qualCtx: qual.ctx, mountCtx }
    const mountProps = getMountProps(ctx)
    return (
      <>
        <ComponentMount {...mountProps} />
        <div className="py-0.5 rounded-4" onClick={() => onClick?.(ctx)}>
          <Option
            name={name}
            icon={customIconDISCOURAGED || (icon && <Icon name={icon} />)}
            endIcon={endIcon && <Icon name={endIcon} />}
            color={theme === "danger" ? "text-th-red-warning" : undefined}
          />
        </div>
      </>
    )
  } else {
    return unreachable(qual)
  }
}

type MenuOptionProps = {
  name: ReactNode
  onClick: () => void
  disabled: string | JSX.Element | false
  theme: ThemeName
  icon: ActionConfig<any, any, any>["icon"]
  endIcon: ActionConfig<any, any, any>["icon"]
}

function MenuButton({ name, onClick, disabled, theme, icon, endIcon }: MenuOptionProps) {
  return (
    <Tooltip inactive={!disabled} message={disabled}>
      <div
        className={cn(
          "p-2 transition-all flex items-center gap-2 rounded-3",
          disabled ? "cursor-not-allowed" : "cursor-pointer",
          !disabled && "hover:bg-th-orange-light2",
          disabled && "opacity-50",
        )}
        onClick={disabled ? undefined : onClick}
      >
        {icon && <Icon name={icon} />}
        <Typography
          variant="bodybold"
          className={cn(theme === "danger" && "text-th-red-warning", "whitespace-nowrap")}
        >
          {name}
        </Typography>
        {endIcon && <Icon name={endIcon} />}
      </div>
    </Tooltip>
  )
}

function Submount<TQualifyCtx, TMountProps, TMountCtx extends object>({
  name,
  richText,
  qualify,
  getMountProps,
  useMountCtx,
  Mount: ComponentMount,
  onClick,
  icon,
  endIcon,
  close,
  theme,
}: ActionConfig<TQualifyCtx, TMountProps, TMountCtx> & { close: () => void }) {
  const mountCtx = useMountCtx()
  const qual = performQualify(qualify)
  const defaultTheme = useContext().theme
  const themeOrDefault = theme ?? defaultTheme ?? "primary"

  if (qual.type === "hidden") {
    return null
  } else if (qual.type === "disabled") {
    return (
      <MenuButton
        name={name || richText}
        onClick={() => undefined}
        disabled={qual.message}
        icon={icon}
        endIcon={endIcon}
        theme={themeOrDefault}
      />
    )
  } else if (qual.type === "qualified") {
    return (
      <>
        <ComponentMount {...getMountProps({ qualCtx: qual.ctx, mountCtx })} />
        <MenuButton
          name={name || richText}
          onClick={() => {
            onClick?.({ qualCtx: qual.ctx, mountCtx })
            close()
          }}
          disabled={false}
          icon={icon}
          endIcon={endIcon}
          theme={themeOrDefault}
        />
      </>
    )
  } else {
    return unreachable(qual)
  }
}

export function Mount<TQualifyCtx, TMountProps, TMountCtx extends object>({
  name,
  richText,
  type = "button",
  options,
  isLoading,
  qualify,
  getMountProps,
  useMountCtx,
  Mount: ComponentMount,
  submit,
  onClick,
  icon,
  endIcon,
  theme,
  internalVariantDONOTUSE,
}: ActionConfig<TQualifyCtx, TMountProps, TMountCtx> & { submit?: boolean }) {
  const mountCtx = useMountCtx()
  const qual = performQualify(qualify)
  const defaultTheme = useContext().theme
  const themeOrDefault = theme ?? defaultTheme ?? "primary"
  const sz = useScreenSize()
  const dropdownOpener = useOpener()

  if (qual.type === "hidden") {
    return null
  } else if (qual.type === "disabled") {
    if (type === "button") {
      return (
        <Button
          icon={icon && <Icon name={icon} />}
          endIcon={endIcon && <Icon name={endIcon} />}
          theme={getButtonThemeFromThemeName(themeOrDefault)}
          disabled
          disabledMessage={qual.message}
          richText={richText}
        >
          {name}
        </Button>
      )
    } else if (type === "dropdown") {
      return (
        <DropdownButton
          icon={
            internalVariantDONOTUSE === "ellipsis" ? (
              <div className="py-1">
                <Icon name="ellipsis" />
              </div>
            ) : (
              icon && <Icon name={icon} />
            )
          }
          theme={getButtonThemeFromThemeName(themeOrDefault)}
          closeButtonTheme={theme2.ButtonThemeLight}
          disabled
          options={[]}
        >
          {name}
        </DropdownButton>
      )
    } else {
      return unreachable(type)
    }
  } else if (qual.type === "qualified") {
    const ctx = { qualCtx: qual.ctx, mountCtx }
    const mountProps = getMountProps(ctx)
    if (type === "button") {
      return (
        <>
          <ComponentMount {...mountProps} />
          <Button
            icon={icon && <Icon name={icon} />}
            theme={getButtonThemeFromThemeName(themeOrDefault)}
            endIcon={endIcon && <Icon name={endIcon} />}
            onClick={() => onClick?.(ctx)}
            isLoading={typeof isLoading === "boolean" ? isLoading : isLoading?.(mountCtx)}
            submit={submit}
            richText={richText}
          >
            {name}
          </Button>
        </>
      )
    } else if (type === "dropdown") {
      return (
        <>
          <ComponentMount {...mountProps} />
          {sz !== "sm" || internalVariantDONOTUSE === "ellipsis" ? (
            (options ?? [])
              .flatMap((x) => (!x.type || x.type === "button" ? x : []))
              .filter((x) => performQualify(x.qualify).type !== "hidden").length > 0 && (
              <Dropdown
                opener={dropdownOpener}
                variant="thin"
                keepMounted
                content={
                  <BaseLayout.VStack gap={1} view={{ overridesDONOTUSE: { minWidth: 160 } }}>
                    {(options ?? []).map((option, i) => (
                      <Fragment key={`${option.name}-${i}`}>
                        {!option.type || option.type === "button" ? (
                          <Submount {...option} close={dropdownOpener.setInactive} />
                        ) : option.type === "divider" ? (
                          <Divider />
                        ) : null}
                      </Fragment>
                    ))}
                  </BaseLayout.VStack>
                }
              >
                {internalVariantDONOTUSE === "ellipsis" ? (
                  <Button
                    theme={getButtonThemeFromThemeName(themeOrDefault)}
                    icon={<Icon name="ellipsis" cursor="pointer" />}
                  />
                ) : (
                  <Button
                    theme={getButtonThemeFromThemeName(themeOrDefault)}
                    endIcon={<Icon name={["chevron", "90"]} height={16} />}
                  >
                    {name}
                  </Button>
                )}
              </Dropdown>
            )
          ) : (
            <DropdownButton
              icon={
                internalVariantDONOTUSE === "ellipsis" ? (
                  <div className="py-1">
                    <Icon name="ellipsis" cursor="pointer" />
                  </div>
                ) : (
                  icon && <Icon name={icon} />
                )
              }
              theme={getButtonThemeFromThemeName(themeOrDefault)}
              closeButtonTheme={theme2.ButtonThemeLight}
              keepMenuMounted
              options={MSArray.collapse(
                options?.map((opt, i) => {
                  if (opt.type === "divider") {
                    return opt
                  } else {
                    // qual needs to be able to operate without mount ctx because otherwise
                    // we wouldn't be able to hide this?
                    const innerQual = performQualify(opt.qualify)
                    if (innerQual.type === "hidden") {
                      return null
                    } else {
                      return {
                        key: `${opt.name}-${i}`,
                        type: "override",
                        disabled: innerQual.type === "disabled",
                        ui: <MenuOptionMount {...opt} />,
                      }
                    }
                  }
                }) ?? [],
              )}
            >
              {name}
            </DropdownButton>
          )}
        </>
      )
    } else {
      return unreachable(type)
    }
  } else {
    return unreachable(qual)
  }
}

export function base<TQualifyCtx, TMountProps, TMountCtx extends object>(
  name: string,
  props: Omit<ActionConfig<TQualifyCtx, TMountProps, TMountCtx>, "name">,
): ActionConfig<TQualifyCtx, TMountProps, TMountCtx> {
  return { name, ...props }
}
