/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ListComplianceSettingsListPayeeOverridesOrdering =
  (typeof ListComplianceSettingsListPayeeOverridesOrdering)[keyof typeof ListComplianceSettingsListPayeeOverridesOrdering]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListComplianceSettingsListPayeeOverridesOrdering = {
  license_required: "license_required",
  "-license_required": "-license_required",
  w9_required: "w9_required",
  "-w9_required": "-w9_required",
  e_verify_required: "e_verify_required",
  "-e_verify_required": "-e_verify_required",
  created_at: "created_at",
  "-created_at": "-created_at",
  updated_at: "updated_at",
  "-updated_at": "-updated_at",
} as const
