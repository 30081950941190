/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type AssignmentListRowStatus =
  (typeof AssignmentListRowStatus)[keyof typeof AssignmentListRowStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AssignmentListRowStatus = {
  complete: "complete",
  pending: "pending",
} as const
