import { useOnMount, useStabilized } from "msutils"

export function compassId(type: string, subtype?: string) {
  return `compass__${type}${subtype ? `__${subtype}` : ""}`
}

export function useKeyListener(key: string, cb: (e: KeyboardEvent) => void) {
  const stableCb = useStabilized(cb)
  useOnMount(() => {
    const handle = (e: KeyboardEvent) => {
      if (e.key === key) stableCb.current(e)
    }
    document.addEventListener("keydown", handle)
    return () => {
      document.removeEventListener("keydown", handle)
    }
  })
}
