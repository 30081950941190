import { ReactNode } from "react"
import { cn } from "msutils/classnames"
import { Collapsable, MSArray } from "msutils/array"
import Typography from "compass/data/Typography"
import useScreenSize from "compass/theme/useScreenSize"
import Metrics from "compass-local/Metrics"
import Metric from "compass-local/Metric"
import { Format, Zero } from "msutils"
import BigNumber from "bignumber.js"
import Tooltip from "compass/layout/Tooltip"

type SupportedColor = "green" | "red" | "yellow" | "grey" | "blue"

type Branded<T> = T & { __metricsBar: true }
function brand<T>(val: T): Branded<T> {
  return val as Branded<T>
}

type ProgressBarMetric = Branded<{
  color: SupportedColor
  name: string
  amount: BigNumber | null
}>

// eslint-disable-next-line mosaic-js/unnamed-args
export function MetricsBarItem(
  name: string,
  color: SupportedColor,
  value: string | BigNumber | null,
): ProgressBarMetric {
  return brand({
    color,
    name,
    amount: value === null ? null : BigNumber(value),
  })
}

type Settings = {
  hideLegend: boolean
}

type Props = {
  metrics: Collapsable<ProgressBarMetric[]>
  legendEnd?: ReactNode
  settings?: Partial<Settings>
  width?: BigNumber
}

const ColorToClassname: Record<SupportedColor, string> = {
  green: "bg-th-green-dark2",
  red: "bg-th-red-dark1",
  yellow: "bg-th-yellow-dark2",
  grey: "bg-th-warmgrey-2",
  blue: "bg-th-blue-light0",
}

export function MetricsBar({ metrics, legendEnd, settings, width }: Props) {
  const screenSize = useScreenSize()
  const collapsedMetrics = MSArray.collapse(metrics)

  const totalAmount = BigNumber.sum(Zero, ...collapsedMetrics.map((x) => x.amount ?? Zero))

  const height = settings?.hideLegend ? "h-[6px]" : "h-[8px]"

  return (
    <div
      className={cn("vflex gap-4", width === undefined && "grow")}
      style={{ width: width ? `${width.toFixed(2)}px` : undefined }}
    >
      <div className="flex gap-1 grow">
        {totalAmount.gt(Zero) ? (
          collapsedMetrics
            .filter((x) => (x.amount ?? 0) > 0)
            .map((metric, i) => {
              if (settings?.hideLegend) {
                return (
                  <div
                    key={i}
                    style={{
                      flexBasis: `${(metric.amount ?? Zero)
                        .div(totalAmount)
                        .multipliedBy(BigNumber(100))
                        .toFixed(2)}%`,
                    }}
                  >
                    <Tooltip
                      message={
                        <div className="vflex gap-1">
                          <div className="flex gap-2 items-center">
                            <div
                              className={cn(
                                "rounded-2 w-2",
                                height,
                                ColorToClassname[metric.color ?? "grey"],
                              )}
                            />
                            <Typography variant="label">{metric.name}</Typography>
                          </div>
                          <Typography variant="bodybold">
                            {Format.currency(metric.amount)}
                          </Typography>
                        </div>
                      }
                    >
                      <div
                        className={cn(
                          "rounded-2 min-w-[8px]",
                          height,
                          ColorToClassname[metric.color],
                        )}
                      />
                    </Tooltip>
                  </div>
                )
              } else {
                return (
                  <div
                    key={i}
                    className={cn("rounded-2 min-w-[8px]", height, ColorToClassname[metric.color])}
                    style={{
                      flexBasis: `${(metric.amount ?? Zero)
                        .div(totalAmount)
                        .multipliedBy(BigNumber(100))
                        .toFixed(2)}%`,
                    }}
                  />
                )
              }
            })
        ) : (
          <div className={cn("rounded-2 grow", height, ColorToClassname.grey)} />
        )}
      </div>
      {!settings?.hideLegend &&
        (screenSize === "sm" ? (
          <div className="vflex gap-2">
            <Metrics bold variant="small" layout="h">
              {collapsedMetrics.map((x, i) => (
                <Metric
                  key={i}
                  k={x.name}
                  v={Format.currency(x.amount === null ? null : BigNumber(x.amount))}
                  labelIcon={
                    <div className={cn("rounded-2 h-2 w-2 shrink-0", ColorToClassname[x.color])} />
                  }
                />
              ))}
            </Metrics>
            <div className="vflex">{legendEnd}</div>
          </div>
        ) : (
          <div className="flex justify-between">
            <div className="flex gap-10">
              <Metrics bold variant="small">
                {collapsedMetrics.map((x, i) => (
                  <Metric
                    key={i}
                    k={x.name}
                    v={Format.currency(x.amount === null ? null : BigNumber(x.amount))}
                    labelIcon={
                      <div
                        className={cn("rounded-2 h-2 w-2 shrink-0", ColorToClassname[x.color])}
                      />
                    }
                  />
                ))}
              </Metrics>
            </div>
            <div className="flex justify-center">{legendEnd}</div>
          </div>
        ))}
    </div>
  )
}
