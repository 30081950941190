import { MutableRefObject, useCallback, useRef } from "react"

export type OpenerInterruptProps = {
  close: () => void
  forceClose: () => void
  shouldCloseRef: MutableRefObject<() => boolean>
}

export function useOpenerInterrupt(close: () => void): OpenerInterruptProps {
  const shouldCloseRef = useRef(() => true)
  const closeWithWarning = useCallback(() => {
    if (shouldCloseRef.current()) {
      close()
    }
  }, [close, shouldCloseRef])
  return { shouldCloseRef, close: closeWithWarning, forceClose: close }
}
