import { BaseLayout } from "../../baseLayout"
import { useTheme, VariantsOf } from "../../theme"

declare module "../../theme" {
  interface ComponentTheme {
    divider: {
      color: string
      thickness: number
    }
  }
}

export default function Divider({
  layout = "h",
  padded,
  variant,
}: {
  layout?: "h" | "v"
  padded?: boolean
  variant?: VariantsOf<"divider">
}) {
  const theme = useTheme("divider", variant)

  return (
    <BaseLayout.NativeDiv
      background={theme.color}
      height={layout === "h" ? theme.thickness : "100%"}
      width={layout === "v" ? theme.thickness : undefined}
      flexShrink={0}
      marginLeft={padded ? 20 : undefined}
      marginRight={padded ? 20 : undefined}
    />
  )
}
