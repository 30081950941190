import { ReactNode } from "react"
import { BaseLayout } from "../baseLayout"
import Divider from "../data/Divider"
import Icon from "../data/Icon"
import Typography from "../data/Typography"
import { useTheme, VariantsOf } from "../theme"
import useScreenSize from "../theme/useScreenSize"
import { useEscapeListener } from "./EscapeContext"
import { useModalContext } from "./Modal"

declare module "../theme" {
  interface ComponentTheme {
    modalBody: {
      headerBackground: string
      closeIconColor: string
      background: string
      borderRadius: number
      shadowColor: string
      padding: number | [number, number]
    }
  }
}

const sizes = { small: 460, large: 720 }

export function ModalBody({
  title,
  variant,
  size = "small",
  footer,
  forceNoFill,
  children,
}: {
  title?: string
  size?: keyof typeof sizes
  variant?: VariantsOf<"modalBody">
  footer?: ReactNode
  forceNoFill?: boolean
  children: ReactNode
}) {
  const theme = useTheme("modalBody", variant)
  const sz = useScreenSize()
  const { close } = useModalContext()

  const width =
    sz === "sm" && !forceNoFill
      ? document.body.clientWidth
      : Math.min(sizes[size], document.body.clientWidth - 20)

  const height = sz === "sm" && !forceNoFill ? document.body.clientHeight : undefined

  useEscapeListener(() => {
    if (sz === "sm") return
    close()
  })

  return (
    <BaseLayout.VStack
      view={{
        fixedWidth: width,
        background: theme.background,
        overridesDONOTUSE: {
          borderRadius: theme.borderRadius,
          boxShadow: `4px 0 10px 4px ${theme.shadowColor}`,
          position: "relative",
          height,
          maxHeight: document.body.clientHeight - (sz === "sm" ? 0 : 20),
          overflowY: "auto",
        },
      }}
    >
      <BaseLayout.HStack
        justify="between"
        align="center"
        view={{
          inset: theme.padding,
          background: theme.headerBackground,
          // TODO: figure out how to not need to specify a zIndex here. Maybe zIndex on sticky is required?
          overridesDONOTUSE: {
            position: "sticky",
            top: 0,
            zIndex: 1,
            borderRadius: theme.borderRadius,
          },
        }}
      >
        <Typography variant="drawerheader">{title}</Typography>
        <Icon
          name="x"
          rawColor={theme.closeIconColor}
          height={16}
          onClick={close}
          cursor="pointer"
        />
      </BaseLayout.HStack>
      <BaseLayout.VStack
        gap={5}
        view={{ inset: [0, 8], overridesDONOTUSE: { paddingBottom: 12, flexGrow: 1 } }}
      >
        {children}
      </BaseLayout.VStack>
      {footer && (
        <BaseLayout.View
          background={theme.headerBackground}
          overridesDONOTUSE={{ position: "sticky", bottom: 0, borderRadius: theme.borderRadius }}
        >
          <BaseLayout.View inset={[0, 5]}>
            <Divider />
          </BaseLayout.View>
          <BaseLayout.View inset={5}>{footer}</BaseLayout.View>
        </BaseLayout.View>
      )}
    </BaseLayout.VStack>
  )
}
