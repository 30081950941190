import { createContext, CSSProperties, ReactNode, useContext, useMemo, useRef } from "react"
import { Opener, useHtmlId } from "msutils"
import { unreachable } from "msutils/misc"
import { createPortal } from "react-dom"
import { AnimatedDiv, useAnimationPhase } from "../_internal/AnimatedDiv"
import { useTheme, VariantsOf } from "../theme"
import { Style } from "../dom-utils"
import useScreenSize from "../theme/useScreenSize"
import { OpenerInterruptProps, useOpenerInterrupt } from "../_internal/OpenerInterruptProvider"
import { PortalRootId } from "../_internal/constants"

declare module "../theme" {
  interface ComponentTheme {
    drawer: {
      shadowColor: string
    }
  }
}

type Side = "right" | "bottom"

function getStylesForSide(side: Side): CSSProperties {
  if (side === "bottom") {
    return { bottom: 0, left: 0, right: 0, display: "flex" }
  } else if (side === "right") {
    return { bottom: 0, top: 0, right: 0, display: "flex", flexDirection: "column" }
  } else {
    return unreachable(side)
  }
}

function getTransitionStylesForSide(side: Side): CSSProperties {
  if (side === "bottom") {
    return { transform: "translateY(100%)" }
  } else if (side === "right") {
    return { transform: "translateX(100%)" }
  } else {
    return unreachable(side)
  }
}

const Ctx = createContext<(OpenerInterruptProps & { side: Side }) | undefined>(undefined)

export function useDrawerContext() {
  const ctx = useContext(Ctx)
  if (!ctx) throw new Error("Context error")
  return ctx
}

export function Drawer({
  opener,
  keepMounted,
  variant,
  side: side_,
  children,
}: {
  opener: Opener
  keepMounted?: boolean
  variant?: VariantsOf<"drawer">
  side?: Side
  children: ReactNode
}) {
  const theme = useTheme("drawer", variant)
  const sz = useScreenSize()
  const side = side_ ?? (sz === "sm" ? "bottom" : "right")
  const bodyWrapperId = useHtmlId()
  const animationPhase = useAnimationPhase(opener.isActive)
  const containerRef = useRef<HTMLDivElement | null>(null)
  const openerInterruptProps = useOpenerInterrupt(opener.setInactive)
  const ctxValue = useMemo(() => ({ ...openerInterruptProps, side }), [side, openerInterruptProps])

  const portalRoot = useMemo(() => document.getElementById(PortalRootId)!, [])
  const content = (
    <AnimatedDiv
      phase={animationPhase}
      style={{ position: "fixed", inset: 0, pointerEvents: "none" }}
      keepMounted={keepMounted}
    >
      <AnimatedDiv
        phase={animationPhase}
        animationProperties="opacity"
        style={{
          position: "absolute",
          inset: 0,
          background: theme.shadowColor,
          pointerEvents: animationPhase === "out" ? undefined : "auto",
        }}
        ref={containerRef}
        keepMounted={keepMounted}
        styleInOut={{ opacity: 0 }}
        onClick={(e) => {
          const clickedInside =
            e.target !== containerRef.current &&
            e.target instanceof Node &&
            containerRef.current?.contains(e.target)

          if (!clickedInside) openerInterruptProps.close()
        }}
      />
      <Style selector={`#${bodyWrapperId} > *`} flexGrow={1} />
      <AnimatedDiv
        id={bodyWrapperId}
        phase={animationPhase}
        keepMounted={keepMounted}
        style={{ position: "absolute", pointerEvents: "auto", ...getStylesForSide(side) }}
        styleInOut={getTransitionStylesForSide(side)}
        animationProperties="transform"
      >
        <Ctx.Provider value={ctxValue}>{children}</Ctx.Provider>
      </AnimatedDiv>
    </AnimatedDiv>
  )
  const portal = createPortal(content, portalRoot)
  return <>{portal}</>
}
