import { cn } from "msutils/classnames"
import { Collapsable, MSArray } from "msutils/array"
import { t } from "content"
import { useOpener } from "msutils"
import Button from ".."
import Divider from "../../Divider"
import Typography from "../../Typography"
import { ButtonUtils } from "../utils"
import Icon from "../../Icon"
import { BaseLayout } from "../../../baseLayout"
import { Drawer } from "../../../v2/Drawer"
import { Option, processOptions } from "./internal-utils"
import { DropdownButtonUtils } from "./utils"

export type Props = {
  options: Collapsable<DropdownButtonUtils.OptionOrDivider[]>
  theme: ButtonUtils.Theme
  closeButtonTheme: ButtonUtils.Theme
  keepMenuMounted?: boolean
  disabled?: boolean
  isLoading?: boolean
  icon?: JSX.Element
  children: string
}

export default function DropdownButton({
  options: unprocessedOptions,
  disabled,
  icon,
  theme,
  closeButtonTheme,
  keepMenuMounted,
  children,
}: Props) {
  const opener = useOpener()
  const options = processOptions(unprocessedOptions)

  if (options.length === 0) return null
  return (
    <>
      <Drawer opener={opener} keepMounted={keepMenuMounted} side="bottom">
        <BaseLayout.VStack
          gap={2}
          view={{
            inset: 5,
            background: "white",
            overridesDONOTUSE: {
              borderTopLeftRadius: 12,
              borderTopRightRadius: 12,
              boxShadow: `-2px 0 10px 6px rgba(0,0,0,0.06)`,
              textAlign: "center",
            },
          }}
        >
          <Typography variant="label" className="text-th-text-secondary">
            {children}
          </Typography>
          {MSArray.collapse(options).map((item, i) =>
            item.type === "divider" ? (
              <div key={i} className="w-full">
                <Divider />
              </div>
            ) : item.type === "override" ? (
              <div
                key={item.key ?? i}
                onClick={!item.disabled ? opener.setInactive : undefined}
                className={cn(item.disabled ? "cursor-not-allowed" : "cursor-pointer")}
              >
                {item.ui}
              </div>
            ) : (
              <div
                key={i}
                onClick={
                  !item.disabled
                    ? () => {
                        item.onClick?.()
                        opener.setInactive()
                      }
                    : undefined
                }
                className={cn(item.disabled ? "cursor-not-allowed" : "cursor-pointer")}
              >
                <Option {...item} key={i} />
              </div>
            ),
          )}
          <Button theme={closeButtonTheme} icon={<Icon name="x" />} onClick={opener.setInactive}>
            {t("Close")}
          </Button>
        </BaseLayout.VStack>
      </Drawer>
      <Button
        icon={icon}
        disabled={disabled}
        theme={theme}
        onClick={() => {
          if (!disabled) opener.setActive()
        }}
      >
        {children}
      </Button>
    </>
  )
}
