import { createContext, MutableRefObject, ReactNode, useContext, useId, useRef } from "react"
import { useOnMount } from "msutils"
import { useKeyListener } from "../_internal/utils"

// Note: This expects that the registration of escape key listeners happens in the reverse order that they should get called.

const Ctx = createContext<MutableRefObject<{ id: string; callback: () => void }[]> | undefined>(
  undefined,
)

export function EscapeContextProvider({ children }: { children: ReactNode }) {
  const handleRef = useRef<{ id: string; callback: () => void }[]>([])
  return <Ctx.Provider value={handleRef}>{children}</Ctx.Provider>
}

export function useEscapeListener(cb: () => void) {
  const id = useId()
  const handle = useContext(Ctx)

  useOnMount(() => {
    if (handle) {
      handle.current = [...handle.current, { id, callback: cb }]
      return () => {
        handle.current = handle.current.filter((x) => x.id !== id)
      }
    }
    return () => {}
  })

  useKeyListener("Escape", () => {
    handle?.current.at(-1)?.callback()
  })
}
