/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type AdminQBRequestFailureReason =
  (typeof AdminQBRequestFailureReason)[keyof typeof AdminQBRequestFailureReason]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AdminQBRequestFailureReason = {
  account_conflict: "account_conflict",
  no_bank_ledger_account: "no_bank_ledger_account",
  line_items_missing_cost_code: "line_items_missing_cost_code",
  expense_card_ledger_account_not_linked: "expense_card_ledger_account_not_linked",
  no_payment_source: "no_payment_source",
  project_push_block: "project_push_block",
  instance_push_block: "instance_push_block",
  parent_not_linked: "parent_not_linked",
  not_wanted: "not_wanted",
  missing_setting: "missing_setting",
  circuit_breaker: "circuit_breaker",
  existing_unconfirmed_push: "existing_unconfirmed_push",
  not_clean: "not_clean",
  invalid_update: "invalid_update",
  subscription_ended: "subscription_ended",
  stale_object: "stale_object",
  account_period_closed: "account_period_closed",
  deleted_reference: "deleted_reference",
  duplicate_number: "duplicate_number",
  duplicate_name: "duplicate_name",
  revoked_access: "revoked_access",
  timeout: "timeout",
  duplicate: "duplicate",
  direct_deposit_edit_restriction: "direct_deposit_edit_restriction",
  other_user_accessing: "other_user_accessing",
  downloaded_transaction: "downloaded_transaction",
  unknown: "unknown",
  incorrect_type: "incorrect_type",
  blocked_source_id: "blocked_source_id",
  do_not_pull: "do_not_pull",
  inbound_sync_filter: "inbound_sync_filter",
  no_difference: "no_difference",
  client_side_filter: "client_side_filter",
  missing_project: "missing_project",
  missing_parent_object: "missing_parent_object",
  unscoped: "unscoped",
  no_parent_object: "no_parent_object",
  no_vendor: "no_vendor",
  no_number: "no_number",
  expense_already_has_attachment: "expense_already_has_attachment",
  unknown_cost_code: "unknown_cost_code",
  duplicate_email: "duplicate_email",
  bad_item_type: "bad_item_type",
  multiple_matches: "multiple_matches",
  null: null,
} as const
