/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type InvoicePermissionUpdatePermissionNotAllowedReason =
  (typeof InvoicePermissionUpdatePermissionNotAllowedReason)[keyof typeof InvoicePermissionUpdatePermissionNotAllowedReason]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InvoicePermissionUpdatePermissionNotAllowedReason = {
  not_creator: "not_creator",
  not_pending_approval: "not_pending_approval",
  is_approved: "is_approved",
  is_void: "is_void",
  payment_exists: "payment_exists",
  another_invoice_with_same_contract_item_exists: "another_invoice_with_same_contract_item_exists",
  is_markup_source: "is_markup_source",
  null: null,
} as const
