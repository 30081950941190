import { ReactNode } from "react"
import Link from "next/link"
import { cn } from "msutils/classnames"
import { MosaicHref } from "modules/routes/types"
import { theme2 } from "theme2"
import Icon from "compass/data/Icon"
import { BaseLayout } from "compass/baseLayout"
import Typography from "compass/data/Typography"

export namespace LinkEXPERIMENTAL {
  type TextOrHyperText = string | [JSX.Element]
  type ContentProps =
    | {
        unstyled?: false
        children?: TextOrHyperText
      }
    | {
        unstyled: true
        children?: ReactNode
      }

  function Content(props: ContentProps) {
    if (props.unstyled) {
      return <>{props.children}</>
    } else {
      if (typeof props.children === "string") {
        return <>{props.children}</>
      } else {
        return <>{props.children?.[0]}</>
      }
    }
  }

  export function open(href: string) {
    window.open(href, "_blank")
  }

  type ExternalProps = ContentProps & {
    href: string
  }

  export function External({ href, ...props }: ExternalProps) {
    return (
      <a
        href={href}
        target="_blank"
        onClick={(e) => e.stopPropagation()}
        className={cn(
          !props.unstyled ? "text-th-text-secondary hover:text-th-text underline" : "!text-inherit",
        )}
        rel="noreferrer"
      >
        <Content {...props} />
      </a>
    )
  }

  type ExternalOrPassthroughProps = ContentProps & {
    href: string | null
  }

  export function ExternalOrPassthrough({ href, ...props }: ExternalOrPassthroughProps) {
    if (href) {
      return <External href={href} {...props} />
    } else {
      return <Content {...props} />
    }
  }

  type InternalProps = {
    label: string
    href: MosaicHref
  }

  export function Internal({ href, label }: InternalProps) {
    return (
      <Link href={href} onClick={(e) => e.stopPropagation()}>
        <BaseLayout.HStack gap={2} align="center">
          <Typography variant="bodybold" rawColor={theme2.colors["brown-1"]}>
            {label}
          </Typography>
          <Icon name={["arrow", "90"]} rawColor={theme2.colors["brown-2"]} />
        </BaseLayout.HStack>
      </Link>
    )
  }

  export function InternalUnstyled({
    href,
    children,
  }: {
    href: MosaicHref | null
    children: ReactNode
  }) {
    if (href) {
      return (
        <Link onClick={(e) => e.stopPropagation()} href={href} className="!text-inherit">
          {children}
        </Link>
      )
    } else {
      return <>{children}</>
    }
  }
}
