import { CSSProperties, forwardRef, HTMLAttributes, useMemo, useState } from "react"
import { delay, random, useOnChangeOnly } from "msutils"
import { BaseLayout } from "../baseLayout"

type AnimationPhaseData = { phase: "in" } | { phase: "between"; tid: string } | { phase: "out" }

// eslint-disable-next-line
export function useAnimationPhase(
  isIn: boolean,
  props?: {
    durationMs?: number
  },
): AnimationPhaseData["phase"] {
  const { durationMs = 100 } = props ?? {}

  const [phaseData, setPhaseData] = useState<AnimationPhaseData>({
    phase: isIn ? "in" : "out",
  })

  useOnChangeOnly([isIn], () => {
    const run = async () => {
      const tid = random()
      setPhaseData({ phase: "between", tid })
      await delay(isIn ? 10 : durationMs)
      setPhaseData((old) => {
        if (old.phase === "between" && old.tid === tid) {
          return { phase: isIn ? "in" : "out" }
        } else {
          return old
        }
      })
    }

    run()
  })

  return phaseData.phase
}

/** Isn't meant to work well with nested animations since children are hidden */
export const AnimatedDiv = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement> & {
    phase: AnimationPhaseData["phase"]
    keepMounted?: boolean
    styleInOut?: CSSProperties
    durationMs?: number
    animationProperties?: string
  }
>((props_, ref) => {
  // eslint-disable-next-line
  const {
    styleInOut,
    phase,
    keepMounted,
    animationProperties = "all",
    durationMs = 100,
    ...props
  } = props_
  const style = useMemo(
    () => (phase === "in" ? props.style : { ...props.style, ...styleInOut }),
    [phase, props.style, styleInOut],
  )

  if (phase === "out" && !keepMounted) {
    return null
  } else {
    return (
      <BaseLayout.RawDiv
        {...props}
        ref={ref}
        style={{
          transition: animationProperties
            .split(" ")
            .map((p) => `ease-in-out ${p}`)
            .join(", "),
          pointerEvents: phase === "in" ? undefined : "none",
          transitionDuration: `${durationMs}ms`,
          ...style,
          ...(phase === "out" && { opacity: 0 }),
        }}
      />
    )
  }
})
