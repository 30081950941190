/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type InvoiceOutboundSyncV2Status =
  (typeof InvoiceOutboundSyncV2Status)[keyof typeof InvoiceOutboundSyncV2Status]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InvoiceOutboundSyncV2Status = {
  new: "new",
  unconfirmed: "unconfirmed",
  completed: "completed",
  failed: "failed",
  inbound: "inbound",
} as const
