/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type AssignmentStatus = (typeof AssignmentStatus)[keyof typeof AssignmentStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AssignmentStatus = {
  complete: "complete",
  pending: "pending",
} as const
